body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;

    --background: var(--purple);
    --accent: var(--white);

    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--accent);
}

* {
    font-family: "Plaster", sans-serif;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
    font-family: sofia-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
}

h1 {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 1.2;
    text-align: center;
    margin: 100px 0 40px;
}

h2 {
    font-weight: 400;
    margin: 50px 0 10px;
}

p {
    margin: 0 0 30px 0;
    font-size: 18px;
}

/* footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-image: radial-gradient(rgba(0, 0, 0, 0) 1px,
            var(--background) 1px);
    background-size: 4px 4px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    font-size: 14px;
    line-height: 14px;
}

footer::before {
    display: block;
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--accent);
    opacity: 0.2;
}

footer svg {
    margin-right: 20px;
}

footer a {
    text-decoration: none;
    color: var(--accent);
}
 */
code {
    font-family: input-mono, monospace;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
}

::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
    background: var(--background);
}

html {
    scroll-snap-type: y mandatory;
}

h1 {
    font-size: 56px;
    font-weight: 600;
    letter-spacing: -3px;
    line-height: 1.2;
    text-align: center;
    margin: 100px 0;
}

h2 {
    margin: 0;
    color: var(--accent);
    left: calc(50% + 130px);
    font-size: 56px;
    font-weight: 600;
    letter-spacing: -3px;
    line-height: 1.2;
    position: absolute;
}

section {
    padding-top: 5vh;
    padding-bottom: 5vh;
    position: relative;
}

/* img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
} */

.progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: var(--accent);
    bottom: 100px;
}

.parallax {
    overflow: hidden;
    letter-spacing: -2px;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    color: var(--white);
}

.parallax .scroller {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 50px;
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
}

.parallax span {
    display: block;
    margin-right: 30px;

}

.glow-back {
    filter: drop-shadow(0 0 10px rgba(119, 53, 136, 0.459));
}