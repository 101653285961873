.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.border-rounded {
  border-radius: 2rem;
}

.padding-5rem {
  padding: 5rem;
}

.vidouter {
  width: 70%;
  height: 70%;
  border-bottom-left-radius: 2rem;
  border-top-right-radius: 2rem;
  overflow: hidden;
  position: relative;
}

.video {
  position: relative;
  left: 0;
  top: 0;
  opacity: 1;
  /* padding: 1rem; */
}

.vidOuterout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
}

.form-section {
  height: 100%;
  margin-top: 6rem;
  position: relative;
  z-index: 6;
  /* background: linear-gradient(to top, rgb(17, 24, 39), rgb(88, 28, 135), rgb(124, 58, 237)) */
}